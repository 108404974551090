import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  container: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    [theme.breakpoints.up('lg')]: {
      width: 745,
    },
  },
  retailersCarousel: {
    marginTop: theme.spacing(3),
  },
  retailer: {
    display: 'flex',
    flexDirection: 'column',
    width: 136,
  },
  retailerImage: {
    alignItems: 'center',
    background: theme.palette.common.white,
    border: '2px solid #E7E7E7',
    borderRadius: 16,
    display: 'flex',
    height: 120,
    justifyContent: 'center',
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    width: 120,
  },
  img: {
    objectFit: 'scale-down',
    width: 80,
    height: 80,
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
});

export default makeStyles(style);
