import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  container: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(5),
  },
  topSection: {
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '100% 0px',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '100% 0px',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '560px auto',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '760px auto',
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: theme.typography.body1.fontSize,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
  },
  topSectionImage: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-start',
      display: 'flex',
      objectFit: 'scale-down',
      width: 320,
    },
    [theme.breakpoints.up('lg')]: {
      alignSelf: 'flex-start',
      display: 'flex',
      objectFit: 'scale-down',
      width: 'auto',
    },
  },
  featuresList: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: theme.spacing(3, 0, 0, 0),
    padding: 0,
    '& > *:last-child': {
      marginBottom: 0,
    },
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  featureItem: {
    alignItems: 'center',
    fontSize: theme.typography.body1.fontSize,
    display: 'flex',
    margin: theme.spacing(2, 0),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
  },
  featureItemIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(1),
  },
  text: {
    fontSize: theme.typography.body1.fontSize,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  topRetailers: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing(4),
    },
  },
});

export default makeStyles(style);
