import React from 'react';
import { Typography } from '@material-ui/core';

import SectionCentered from 'modules/layout/SectionCentered';

import TermsButton from './TermsButton';
import useStyles from './styles';

const AddCashbackSection = () => {
  const classes = useStyles();

  return (
    <SectionCentered
      newLayout
      backgroundColor="white"
      classes={{ container: classes.container }}
    >
      <Typography variant="h3" color="primary" className={classes.title}>
        Join 350,000 cardholders already saving money off their UW bill every
        month.
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        All this for just{' '}
        <Typography component="span" variant="h4" className={classes.price}>
          £3.00
        </Typography>{' '}
        / month
      </Typography>
      <TermsButton />
    </SectionCentered>
  );
};

export default AddCashbackSection;
