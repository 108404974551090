import React from 'react';
import PropTypes from 'prop-types';

import { sanitiseText } from 'redux/utils/sanitise';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField as MuiTextField,
  FormHelperText,
  InputLabel,
  Typography,
} from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  form: { marginTop: theme.spacing(4) },
  labelRoot: {
    color: 'inherit',
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(0.5),
    marginLeft: '0.25em',
  },
  input: {
    borderRadius: theme.spacing(2.5, 2.5, 0, 0),
    borderColor: theme.palette.grey['200'],
  },
}));

const TextField = ({
  id,
  dataTestId,
  dataPayment,
  onChange,
  value,
  label,
  type = 'text',
  disabled = false,
  placeholder,
  error,
  errorMessage,
  name,
  helperText = '',
  required = false,
  textFieldClasses = {},
  InputProps = {},
  ref,
  ...textFieldProps
}) => {
  const classes = useStyles();
  const fieldId = id || name || dataTestId;
  const inputProps = {
    error: !disabled ? error : null,
    disabled,
    required,
  };
  helperText = error && !disabled ? errorMessage : helperText;
  return (
    <div className={classnames(textFieldClasses.form, classes.form)}>
      {label && (
        <InputLabel
          disabled={disabled}
          required={required}
          classes={{
            root: classnames(classes.labelRoot, textFieldClasses.label),
          }}
          htmlFor={fieldId}
        >
          <Typography className={classes.label} component="span">
            {label}
          </Typography>
        </InputLabel>
      )}
      <MuiTextField
        ref={ref}
        id={fieldId}
        data-test-id={dataTestId}
        type={type}
        placeholder={placeholder}
        value={value || ''}
        name={name}
        onChange={(event) => onChange(event, sanitiseText(event.target.value))}
        variant="filled"
        hiddenLabel={true}
        InputProps={{
          classes: {
            root: classnames(textFieldClasses.input, classes.input),
          },
          ...InputProps,
        }}
        inputProps={{
          'data-payment': dataPayment || null,
          'data-test-id': dataTestId,
          id: fieldId,
        }}
        {...inputProps}
        {...textFieldProps}
      />
      {helperText && (
        <FormHelperText {...inputProps}>
          <Typography
            className={textFieldClasses.helperText}
            variant="caption"
            color={error ? 'error' : 'textSecondary'}
          >
            {helperText}
          </Typography>
        </FormHelperText>
      )}
    </div>
  );
};

TextField.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  dataPayment: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  helperText: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataTestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textFieldClasses: PropTypes.shape({
    input: PropTypes.string,
    label: PropTypes.string,
    helperText: PropTypes.string,
    form: PropTypes.string,
  }),
};

const ForwardedTextField = React.forwardRef((props, ref) => (
  <TextField {...props} ref={ref} />
));

ForwardedTextField.displayName = 'TextField';

export default ForwardedTextField;
