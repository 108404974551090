import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { isEmpty } from 'lodash';
import AliceCarousel from 'react-alice-carousel';
import { Typography, useTheme } from '@material-ui/core';
import { FlagPointIcon } from '@utilitywarehouse/partner-ui-icons';
import 'react-alice-carousel/lib/alice-carousel.css';

import SectionCentered from 'modules/layout/SectionCentered';
import LoadingIndicator from 'modules/Shared/LoadingIndicator';

import Retailer from '../Retailers/Retailer';
import { orderByMarketingTier } from '../utils/retailers';
import useStyles from './styles';

const useResponsive = () => {
  const theme = useTheme();
  return {
    [theme.breakpoints.values['sm']]: { items: 4 },
    [theme.breakpoints.values['xs']]: { items: 2 },
  };
};

const TopSection = ({ retailers, isLoading, error }) => {
  const classes = useStyles();
  const responsive = useResponsive();
  const topRetailers = orderByMarketingTier(retailers);

  return (
    <SectionCentered
      newLayout
      backgroundColor="white"
      classes={{ container: classes.container }}
    >
      <div className={classes.topSection}>
        <div>
          <Typography variant="h2" color="primary" className={classes.title}>
            Money off your monthly UW bill
          </Typography>
          <Typography className={classes.subtitle}>
            For every pound you spend with our cashback card, you can earn up to
            10% cashback which comes directly off your monthly UW bill.
          </Typography>
          <ul className={classes.featuresList}>
            <li className={classes.featureItem}>
              <FlagPointIcon className={classes.featureItemIcon} />
              <Typography className={classes.text}>
                Earn{' '}
                <Typography
                  component="span"
                  className={clsx(classes.text, classes.bold)}
                >
                  up to 10%
                </Typography>{' '}
                cashback with our retail partners
              </Typography>
            </li>
            <li className={classes.featureItem}>
              <FlagPointIcon className={classes.featureItemIcon} />
              <Typography className={classes.text}>
                Earn 1% everywhere else*
              </Typography>
            </li>
          </ul>
          {isLoading && <LoadingIndicator loading />}
          {!isLoading && isEmpty(error) && !isEmpty(retailers) && (
            <div className={classes.topRetailers}>
              <AliceCarousel
                autoPlay
                autoPlayInterval={1500}
                disableButtonsControls
                disableDotsControls
                infinite
                responsive={responsive}
              >
                {topRetailers?.map(({ id, name, logo, percentage }) => (
                  <Retailer
                    className="item"
                    data-value={id}
                    key={id}
                    name={name}
                    logo={logo}
                    percentage={percentage}
                  />
                ))}
              </AliceCarousel>
            </div>
          )}
        </div>
        <img
          className={classes.topSectionImage}
          alt="cashback mobile app"
          src="images/cashback/phoneAppPresentation.png"
        />
      </div>
    </SectionCentered>
  );
};

TopSection.propTypes = {
  retailers: PropTypes.array,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
};

export default TopSection;
