/**
 * Order is lowest first, other than 0, which is always last.
 * Does not mutate the original array
 */
export const orderByMarketingTier = (retailers) =>
  [...retailers].sort((a, b) => {
    const tierA = a.marketingTier || b.marketingTier + 1;
    const tierB = b.marketingTier || a.marketingTier + 1;

    return tierA - tierB;
  });
