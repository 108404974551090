import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(5),
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      width: 658,
    },
  },
  subtitle: {
    marginBottom: theme.spacing(5),
  },
  price: {
    color: theme.palette.success.main,
  },
});

export default makeStyles(style);
