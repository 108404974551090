import React from 'react';
import PropTypes from 'prop-types';
import truncate from 'lodash/truncate';
import { Typography } from '@material-ui/core';

import useStyles from './styles';

const Review = ({ text, consumer }) => {
  const classes = useStyles();

  return (
    <div className={classes.reviewContainer}>
      <img
        src="images/cashback/icons/Quote.svg"
        alt="quote icon"
        className={classes.icon}
      />
      <div className={classes.textContainer}>
        <Typography varinat="body1">
          "{truncate(text, { length: 164 })}"
        </Typography>
      </div>
      <Typography className={classes.displayName}>
        {consumer?.displayName}
      </Typography>
    </div>
  );
};

Review.propTypes = {
  text: PropTypes.string.isRequired,
  consumer: PropTypes.shape({
    displayName: PropTypes.string,
  }).isRequired,
};

export default Review;
