import React from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { BrakeDivider } from '@utilitywarehouse/partner-ui-mui-components';
import { useTracking } from '@utilitywarehouse/partner-tracking-react';

import { EVENTS } from 'app/lib/analytics/constants';
import SectionCentered from 'modules/layout/SectionCentered';
import Carousel from 'modules/Shared/Carousel';
import useStyles from './styles';

const RetailersSection = () => {
  const tracking = useTracking();
  const classes = useStyles();
  const theme = useTheme();

  const handleCarouselChange = () => {
    tracking.track(EVENTS.CBC_ON_THE_GO_CAROUSEL_CLICKED);
  };

  return (
    <>
      <BrakeDivider
        className={classes.divider}
        topColor={theme.palette.common.white}
        bottomColor={theme.palette.background.default}
      />
      <SectionCentered newLayout classes={{ container: classes.container }}>
        <Typography variant="h3" color="primary" className={classes.title}>
          Cashback on-the-go
        </Typography>
        <Carousel mouseTracking onSlideChanged={handleCarouselChange}>
          <div className={classes.carouselPage}>
            <img
              src="images/cashback/carousel1.png"
              className={classes.carouselPageImage}
            />
            <div className={classes.descriptionWrapper}>
              <img
                onDragStart={(e) => e.preventDefault()}
                src="images/cashback/icons/App.svg"
                className={classes.carouselIcon}
              />
              <Typography variant="h2" className={classes.text}>
                Convenience in your pocket
              </Typography>
              <Typography variant="body1" className={classes.description}>
                Our shiny UW app makes it easy to check and top up your cashback
                balance, marvel at your cashback earnings, view your PIN and
                much more.
              </Typography>
            </div>
          </div>
          <div className={classes.carouselPage}>
            <img
              src="images/cashback/carousel2.png"
              className={classes.carouselPageImage}
            />
            <div className={classes.descriptionWrapper}>
              <img
                onDragStart={(e) => e.preventDefault()}
                src="images/cashback/icons/People.svg"
                className={classes.carouselIcon}
              />
              <Typography variant="h2" className={classes.text}>
                Maximise your cashback potential
              </Typography>
              <Typography variant="body1" className={classes.description}>
                Add an additional cardholder to your account so both you and
                someone else in your household can earn as you shop.
              </Typography>
            </div>
          </div>
          <div className={classes.carouselPage}>
            <img
              onDragStart={(e) => e.preventDefault()}
              src="images/cashback/carousel3.png"
              className={classes.carouselPageImage}
            />
            <div className={classes.descriptionWrapper}>
              <img
                src="images/cashback/icons/Savings.svg"
                className={classes.carouselIcon}
              />
              <Typography variant="h2" className={classes.text}>
                Never forget to top up again
              </Typography>
              <Typography className={classes.description}>
                Automatically top up your Cashback Card weekly, monthly, or when
                your balance falls below a set amount.
              </Typography>
            </div>
          </div>
          <div className={classes.carouselPage}>
            <img
              onDragStart={(e) => e.preventDefault()}
              src="images/cashback/carousel4.png"
              className={classes.carouselPageImage}
            />
            <div className={classes.descriptionWrapper}>
              <img
                src="images/cashback/icons/Safety.svg"
                className={classes.carouselIcon}
              />
              <Typography variant="h2" className={classes.text}>
                We've got you covered
              </Typography>
              <Typography variant="body1" className={classes.description}>
                Our in-app security features help to keep your money safe. Block
                lost cards, disable cash machine withdrawals, and switch off
                contactless payments.
              </Typography>
            </div>
          </div>
          <div className={classes.carouselPage}>
            <img
              onDragStart={(e) => e.preventDefault()}
              src="images/cashback/carousel5.png"
              className={classes.carouselPageImage}
            />
            <div className={classes.descriptionWrapper}>
              <img
                src="images/cashback/icons/Clock.svg"
                className={classes.carouselIcon}
              />
              <Typography variant="h2" className={classes.text}>
                Real-time cashback earnings
              </Typography>
              <Typography variant="body1" className={classes.description}>
                Unlike other cashback cards, we’ll show you your estimated
                cashback as soon as you shop. Your earned cashback will be
                deducted from your next monthly UW bill.
              </Typography>
            </div>
          </div>
        </Carousel>
      </SectionCentered>
      <BrakeDivider
        className={classes.divider}
        topColor={theme.palette.background.default}
        bottomColor={theme.palette.common.white}
      />
    </>
  );
};

export default RetailersSection;
