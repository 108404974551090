import React, { useState } from 'react';
import { Button, Typography, makeStyles } from '@material-ui/core';
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from '@utilitywarehouse/partner-ui-icons';
import { useTracking } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS } from 'app/lib/analytics/constants';
import SectionCentered from 'modules/layout/SectionCentered';
import { CBC_TERMS_URL } from 'app/config/externalLinks';
import Link from 'modules/Shared/Link';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(2),
  },
  button: {
    padding: theme.spacing(0),
    textAlign: 'left',
  },
  buttonText: {
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    [theme.breakpoints.up('md')]: {
      width: 700,
    },
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  content: {
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      width: 700,
    },
  },
}));

const LegalBit = () => {
  const tracking = useTracking();
  const classes = useStyles();
  const [showLegalBit, setShowLegalBit] = useState(false);

  const handleLegalBitClick = () => {
    tracking.track(EVENTS.CBC_TERMS_CLICKED);
    setShowLegalBit(!showLegalBit);
  };

  return (
    <SectionCentered
      sectionCenteredClasses={{
        root: classes.container,
      }}
    >
      <Button
        variant="link"
        onClick={handleLegalBitClick}
        endIcon={showLegalBit ? <ChevronUpIcon /> : <ChevronDownIcon />}
        classes={{ root: classes.button, endIcon: classes.icon }}
      >
        <span className={classes.buttonText}>Terms and Conditions</span>
      </Button>
      {showLegalBit && (
        <Typography variant="body2" component="div" className={classes.content}>
          <p>
            *You can earn unlimited cashback at the relevant % from our retail
            partners each month. Purchases from other retailers are eligible for
            cashback of 1% on spend, subject to cashback caps which vary
            depending on the number of services you have taken out. You are only
            eligible to receive the 1% cashback at other retailers if you are
            not engaged in debt collection proceedings with UW - and have at
            least one of the following services live at the time of billing:
            Mobile, Broadband (Broadband and/or Home Phone) or Energy. See our
            Cashback Card terms and conditions{' '}
            <Link
              href={CBC_TERMS_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </Link>
            .
          </p>
          <p>
            **Calculated using the average cashback of £129.68 earned between
            6/3/22 and 25/5/22 by the top 10% of active account holders (those
            who used their card at least once a week during the same period)
            which has been annualised to give an average yearly cashback earned
            figure of £421.
          </p>
          <p>
            ***Based on annualised cashback earnings paid to customers between
            February - May 2022, inclusive.
          </p>
          <p>
            Our cashback card provider PSI-Pay Ltd is an authorised e-money
            institution. e-money is not subject to Finance Services Compensation
            Scheme protection in the way bank deposits are, but it is subject to
            “safeguarding” requirements. In the unlikely event of insolvency,
            this means there will be a pool of funds from which cashback card
            customers would be repaid (subject to certain deductions for
            associated insolvency costs).
          </p>
          <p>
            The UW cashback card is issued by PSI-Pay Ltd pursuant to a license
            by Mastercard International Incorporated. PSI-Pay Ltd is regulated
            by the Financial Conduct Authority (FCA) under the electronic Money
            Regulations 2011 (FCA register number 900011) for the issuing of
            electronic money. Mastercard is a registered trademark, and the
            circles design is a trademark of Mastercard International
            Incorporated.
          </p>
        </Typography>
      )}
    </SectionCentered>
  );
};

export default LegalBit;
