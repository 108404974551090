import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Typography, useTheme } from '@material-ui/core';
import { useTracking } from '@utilitywarehouse/partner-tracking-react';

import { EVENTS, EVENT_TYPE } from 'app/lib/analytics/constants';
import SectionCentered from 'modules/layout/SectionCentered';
import Carousel from 'modules/Shared/Carousel';
import LoadingIndicator from 'modules/Shared/LoadingIndicator';

import { orderByMarketingTier } from '../utils/retailers';
import Retailer from './Retailer';
import useStyles from './styles';

const useResponsive = () => {
  const theme = useTheme();
  return {
    [theme.breakpoints.values['xl']]: { items: 7 },
    [theme.breakpoints.values['lg']]: { items: 7 },
    [theme.breakpoints.values['md']]: { items: 5 },
    [theme.breakpoints.values['sm']]: { items: 4 },
    [theme.breakpoints.values['xs']]: { items: 2 },
  };
};

const RetailersSection = ({ retailers, isLoading, error }) => {
  const analytics = useTracking();
  const classes = useStyles();
  const responsive = useResponsive();
  const topRetailers = orderByMarketingTier(retailers);

  const handleCarouselSlideChange = () => {
    analytics.track(EVENTS.CBC_RETAILERS_CAROUSEL_CLICKED, {
      event_type: EVENT_TYPE.BEHAVIOUR,
    });
  };

  return (
    <SectionCentered newLayout classes={{ container: classes.container }}>
      <Typography variant="h3" color="primary" className={classes.title}>
        Our retail partners
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        Maximise your cashback earnings by shopping at our retail partners.
        These major brands offer up to 10% cashback, with no cap on how much you
        can earn!
      </Typography>
      <div>
        {isLoading && <LoadingIndicator loading />}
        {!isEmpty(error) && (
          <Typography className={classes.error} paragraph>
            Something went wrong getting the reviews. {error?.message}
          </Typography>
        )}
        {!isLoading && isEmpty(error) && (
          <div className={classes.retailersCarousel}>
            <Carousel
              mouseTracking
              responsive={responsive}
              controlsStrategy="alternate"
              showIndicators={false}
              onSlideChanged={handleCarouselSlideChange}
            >
              {topRetailers?.map(({ id, name, logo, percentage }) => (
                <Retailer
                  className="item"
                  data-value={id}
                  key={id}
                  name={name}
                  logo={logo}
                  percentage={percentage}
                />
              ))}
            </Carousel>
          </div>
        )}
      </div>
    </SectionCentered>
  );
};

RetailersSection.propTypes = {
  retailers: PropTypes.array,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
};

export default RetailersSection;
