import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { Typography } from '@material-ui/core';

import useStyles from './styles';

const DEFAULT_LOGO = 'images/cashback/retailers/placeholder.png';

const RetailersSection = ({ logo, percentage, name }) => {
  const classes = useStyles();

  return (
    <div className={classes.retailer}>
      <div className={classes.retailerImage}>
        <img
          className={classes.img}
          alt={name}
          src={logo || DEFAULT_LOGO}
          onDragStart={(e) => e.preventDefault()}
        />
      </div>
      <Typography className={clsx(classes.text, classes.bold)}>
        {percentage}%
      </Typography>
      <Typography className={classes.text}>{name}</Typography>
    </div>
  );
};

RetailersSection.propTypes = {
  logo: PropTypes.string,
  percentage: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default RetailersSection;
