import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },
  },
  stat: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
      width: 'auto',
    },
    [theme.breakpoints.up('lg')]: {
      width: 'auto',
    },
  },
  text: {
    fontSize: theme.typography.body1.fontSize,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
  },
});

export default makeStyles(style);
