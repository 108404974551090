import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  container: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  stepContainer: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },
  },
  step: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  image: {
    display: 'none',
    height: 300,
    objectFit: 'scale-down',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  icon: {
    color: theme.palette.secondary.main,
    display: 'none',
    fontSize: 64,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(-16),
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  text: {
    fontSize: theme.typography.h4.fontSize,
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up('sm')]: {
      fontSize: 24,
    },
  },
  description: {
    textAlign: 'center',
  },
});

export default makeStyles(style);
