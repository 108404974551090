import React from 'react';
import { Typography } from '@material-ui/core';
import { ChevronRightIcon } from '@utilitywarehouse/partner-ui-icons';

import SectionCentered from 'modules/layout/SectionCentered';
import useStyles from './styles';

const HowItWorksSection = () => {
  const classes = useStyles();

  return (
    <SectionCentered
      newLayout
      backgroundColor="white"
      classes={{ container: classes.container }}
    >
      <Typography variant="h3" color="primary" className={classes.title}>
        How it works
      </Typography>
      <div className={classes.stepContainer}>
        <div className={classes.step}>
          <img
            className={classes.image}
            alt="cashback mobile app"
            src="images/cashback/howItWorks1.png"
          />
          <Typography variant="h4" className={classes.text}>
            Top up
          </Typography>
          <Typography variant="body1" className={classes.description}>
            Load money onto the Cashback Card from your debit card using the UW
            app or website.
          </Typography>
        </div>
        <ChevronRightIcon className={classes.icon} />
        <div className={classes.step}>
          <img
            className={classes.image}
            alt="cashback mobile app"
            src="images/cashback/howItWorks2.png"
          />
          <Typography variant="h4" className={classes.text}>
            Go shopping
          </Typography>
          <Typography variant="body1" className={classes.description}>
            Shop with your Cashback Card and automatically earn up to 10%
            cashback on every transaction.
          </Typography>
        </div>
        <ChevronRightIcon className={classes.icon} />
        <div className={classes.step}>
          <img
            className={classes.image}
            alt="cashback mobile app"
            src="images/cashback/howItWorks3.png"
          />
          <Typography variant="h4" className={classes.text}>
            Lower your bill
          </Typography>
          <Typography variant="body1" className={classes.description}>
            At the end of every month we'll automatically take your cashback
            earnings off your UW bill.
          </Typography>
        </div>
      </div>
    </SectionCentered>
  );
};

export default HowItWorksSection;
