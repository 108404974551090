import React from 'react';
import { Typography } from '@material-ui/core';

import SectionCentered from 'modules/layout/SectionCentered';
import useStyles from './styles';

const StatsSection = () => {
  const classes = useStyles();

  return (
    <SectionCentered classes={{ container: classes.container }}>
      <div className={classes.stat}>
        <Typography variant="h1" color="primary">
          330k
        </Typography>
        <Typography className={classes.text}>Cashback cardholders</Typography>
      </div>
      <div className={classes.stat}>
        <Typography variant="h1" color="primary">
          £421
        </Typography>
        <Typography className={classes.text}>
          Avg. yearly savings by top cardholders**
        </Typography>
      </div>
      <div className={classes.stat}>
        <Typography variant="h1" color="primary">
          £15k
        </Typography>
        <Typography className={classes.text}>
          Saved on bills every day***
        </Typography>
      </div>
    </SectionCentered>
  );
};

export default StatsSection;
