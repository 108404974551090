import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  container: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  text: {
    order: 1,
    fontSize: 20,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  divider: {
    marginTop: -19,
    marginBottom: -6,
  },
  carouselPage: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  carouselPageImage: {
    display: 'none',
    marginRight: theme.spacing(8),
    width: 'auto',
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
    },
  },
  description: {
    maxWidth: 400,
    order: 3,
  },
  descriptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      alignItems: 'flex-start',
      textAlign: 'left',
    },
  },
  carouselIcon: {
    order: 2,
    height: 64,
    marginBottom: theme.spacing(2),
    width: 'auto',
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
});

export default makeStyles(style);
