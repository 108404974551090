import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Typography, useTheme } from '@material-ui/core';
import { useTracking } from '@utilitywarehouse/partner-tracking-react';

import { EVENTS, EVENT_TYPE } from 'app/lib/analytics/constants';
import Carousel from 'modules/Shared/Carousel';
import SectionCentered from 'modules/layout/SectionCentered';
import LoadingIndicator from 'modules/Shared/LoadingIndicator';

import Review from './Review';
import useStyles from './styles';

const useResponsive = () => {
  const theme = useTheme();
  return {
    1600: { items: 4 }, //NOTE: custom breakpoint, xl is too big, we want to show 4 reviews
    [theme.breakpoints.values['lg']]: { items: 3 },
    [theme.breakpoints.values['sm']]: { items: 2 },
    [theme.breakpoints.values['xs']]: { items: 1 },
  };
};

const TrustpilotSection = ({ reviews, isLoading, error, fetchReviews }) => {
  const analytics = useTracking();
  const classes = useStyles();
  const responsive = useResponsive();

  useEffect(() => {
    if (isEmpty(reviews)) fetchReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCarouselChange = () => {
    analytics.track(EVENTS.CBC_TRUSTPILOT_CAROUSEL_CLICKED, {
      event_type: EVENT_TYPE.BEHAVIOUR,
    });
  };

  const showReviews = !isLoading && isEmpty(error) && !isEmpty(reviews);
  return (
    <SectionCentered
      newLayout
      backgroundColor="white"
      classes={{ container: classes.container }}
    >
      <div className={classes.titleContainer}>
        <Typography variant="h3" color="primary" className={classes.title}>
          What our customers say
        </Typography>
        <img
          src="images/cashback/Trustpilot.png"
          alt="trustpilot logo"
          height="100%"
        />
      </div>
      <Typography variant="body1">Showing our 5-star reviews</Typography>
      {isLoading && <LoadingIndicator loading />}
      {!isEmpty(error) && (
        <Typography className={classes.error} paragraph>
          Something went wrong getting the reviews. {error?.message}
        </Typography>
      )}
      {showReviews && (
        <div className={classes.reviewsCarousel}>
          <Carousel
            mouseTracking
            responsive={responsive}
            controlsStrategy="alternate"
            showIndicators={false}
            onSlideChanged={handleCarouselChange}
          >
            {reviews?.map(({ id, title, text, consumer }) => (
              <Review
                className="item"
                data-value={id}
                key={id}
                title={title}
                text={text}
                consumer={consumer}
              />
            ))}
          </Carousel>
        </div>
      )}
    </SectionCentered>
  );
};

TrustpilotSection.propTypes = {
  reviews: PropTypes.array,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  fetchReviews: PropTypes.func.isRequired,
};

export default TrustpilotSection;
