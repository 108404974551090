import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  container: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(5),
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  reviewsCarousel: {
    marginTop: theme.spacing(3),
  },
  reviewContainer: {
    border: '2px solid #E7E7E7',
    borderRadius: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: 314,
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    width: 276,
    left: '50%',
    position: 'relative',
    transform: 'translateX(-50%)',
    [theme.breakpoints.up('sm')]: {
      position: 'initial',
      left: 'initial',
      transform: 'initial',
    },
  },
  icon: {
    display: 'block',
    height: 24,
    width: '24px',
  },
  textContainer: {
    height: '100%',
    marginTop: theme.spacing(1),
    textAlign: 'left',
    overflow: 'hidden',
  },
  displayName: {
    display: 'flex',
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(1),
  },
  error: {
    color: theme.palette.error.dark,
  },
});

export default makeStyles(style);
